/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/layout';
import { getTranslate } from 'react-localize-redux';
import {
  hideAllNotifications,
  showNotification,
  SEVERITY,
} from '../state/notifications';
import * as yup from 'yup';
import FormField from '../components/FormField';
import { Form } from 'formik';
import { Container } from '../components';
import { withFormik } from 'formik';
import RecaptchaButton from '../components/RecaptchaButton';
import * as api from '../utils/api';
import { handleFormValidationError } from '../utils/formError';
import { locNavigate } from '../state/session';
import Spinner from '../components/Spinner';
import * as queryString from 'query-string';
import * as analytics from '../utils/analytics';

const eInvoicingSchema = translate =>
  yup.object().shape({
    companyName: yup
      .string()
      .required(translate('eInvoicingForm.message.requiredField')),
    vatNumber: yup
      .string()
      .required(translate('eInvoicingForm.message.requiredField')),
    customerNumber: yup
      .string()
      .required(translate('eInvoicingForm.message.requiredField')),
    contactPersonName: yup
      .string()
      .required(translate('eInvoicingForm.message.requiredField')),
    contactPersonPhonenumber: yup
      .string()
      .required(translate('eInvoicingForm.message.requiredField')),
    contactPersonEmail: yup
      .string()
      .required(translate('eInvoicingForm.message.requiredField'))
      .email(translate('eInvoicingForm.message.invalidEmail')),
    address: yup
      .string()
      .required(translate('eInvoicingForm.message.requiredField')),
    operator: yup
      .string()
      .required(translate('eInvoicingForm.message.requiredField')),
    ovt: yup
      .string()
      .required(translate('eInvoicingForm.message.requiredField')),
    startTime: yup
      .string()
      .required(translate('eInvoicingForm.message.requiredField')),
    recaptcha: yup.string().required(),
  });

const EInvoicingForm = ({
  translate,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  nocaptcha,
}) => {
  return (
    <Form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <FormField
        name="companyName"
        label={translate('eInvoicingForm.companyName')}
      />
      <FormField
        name="vatNumber"
        label={translate('eInvoicingForm.vatNumber')}
      />
      <FormField
        name="customerNumber"
        label={translate('eInvoicingForm.customerNumber')}
      />
      <FormField
        name="contactPersonName"
        label={translate('eInvoicingForm.contactPersonName')}
      />
      <FormField
        name="contactPersonPhonenumber"
        label={translate('eInvoicingForm.contactPersonPhonenumber')}
      />
      <FormField
        name="contactPersonEmail"
        label={translate('eInvoicingForm.contactPersonEmail')}
      />
      <FormField name="address" label={translate('eInvoicingForm.address')} />
      <FormField name="operator" label={translate('eInvoicingForm.operator')} />
      <FormField name="ovt" label={translate('eInvoicingForm.ovt')} />
      <FormField
        name="startTime"
        label={translate('eInvoicingForm.startTime')}
      />
      <RecaptchaButton
        buttonText="eInvoicingForm.send"
        translate={translate}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        nocaptcha={nocaptcha}
      />
    </Form>
  );
};

const EInvoicingFormik = withFormik({
  mapPropsToValues: ({ vo }) =>
    Object.assign({
      companyName: vo.companyName || '',
      vatNumber: vo.vatNumber || '',
      customerNumber: vo.customerNumber || '',
      contactPersonName: vo.contactPersonName || '',
      contactPersonPhonenumber: vo.contactPersonPhonenumber || '',
      contactPersonEmail: vo.contactPersonEmail || '',
      address: vo.address || '',
      operator: vo.operator || '',
      ovt: vo.ovt || '',
      startTime: vo.startTime || '',
      recaptcha: vo.recaptcha || '',
    }),
  validationSchema: ({ translate }) => {
    return eInvoicingSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'EInvoicingInputForm',
})(EInvoicingForm);

const EInvoicingPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { nocaptcha } = queryParams;

  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setShowSpinner(true);
      dispatch(hideAllNotifications());
      try {
        await api.sendEInvoicingForm({ ...vo, locale });
        dispatch(
          locNavigate('/thank-you', '', {
            state: { message: translate('eInvoicingForm.message.success') },
          })
        );
        //dispatch(showNotification('eInvoicingForm.message.success', SEVERITY.INFO));
        setSubmitting(false);
        setShowSpinner(false);
      } catch (error) {
        setSubmitting(false);
        setShowSpinner(false);
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error(error);
        dispatch(
          showNotification('eInvoicingForm.message.failed', SEVERITY.ERROR)
        );
      }
      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, locale, translate]
  );
  const formProps = { vo, onSubmit, translate, nocaptcha };

  return (
    <Layout
      title={translate('eInvoicingForm.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container
        sx={{
          maxWidth: 1024,
          p: [3, 4],
        }}
      >
        <Styled.h1>{translate('eInvoicingForm.title')}</Styled.h1>
        <EInvoicingFormik key={locale} {...formProps} />
        {showSpinner && <Spinner size="medium" position="fixed" />}
      </Container>
    </Layout>
  );
};

export default EInvoicingPage;
